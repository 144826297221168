<template>
  <div class="project-logo-container rounded-lg">
    <div class="project-logo rounded-lg">
      <img
        class="logo"
        :src="project.logo_url ? project.logo_url : `${publicPath}img/logo-placeholder.png`"
        :alt="project.name"
        height="94"
        width="94"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'project-image',
  props: {
    project: { type: Object, default: null },
  },
  computed: {
    publicPath() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style lang="sass" scoped>
.project-logo-container
  background-color: white
</style>

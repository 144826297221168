<template>
  <GoogleMapMarker :google="google" :map="map" :options="options" @drag="handleMarker" :removeOldMarkers="removeOldMarkers" />
</template>

<script>
import GoogleMapMarker from '@/components/GoogleMapMarker';

export default {
  name: 'camera-map-marker',
  components: {
    GoogleMapMarker,
  },
  props: {
    google: { type: Object, required: true },
    map: { type: Object, required: true },
    camera: { type: Object, required: true },
    removeOldMarkers: {}
  },
  data() {
    return {
      options: {
        position: {
          lat: 19.3996876,
          lng: -99.1589619,
        },
        icon: POINT_MARKER_ICON_CONFIG,
        draggable: true,
      },
    };
  },
  methods: {
    handleMarker(marker) {
      this.latitude = marker.lat;
      this.longitude = marker.lng;
      this.$emit('drag', marker);
    },
  },
  created() {
    this.options.position.lat = this.camera.latitude;
    this.options.position.lng = this.camera.longitude;
  },
};

const POINT_MARKER_ICON_CONFIG = {
  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 1,
  strokeWeight: 5,
  strokeColor: '#FFFFFF',
  fillColor: '#4A62D4',
  fillOpacity: 1,
  scale: 3,
};
</script>

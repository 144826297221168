<template>
  <div style="height: 100%; width: 100%">
    

    <input
      class="border-none bg-transparent w-full focus:outline-none focus:ring-0"
      type="text"
      name="search"
      placeholder="Buscar"
      ref="autocompleteMap"
      style="background-color: white;"
    />
    

    <div class="google-map" ref="googleMap">
      <template v-if="Boolean(this.google) && Boolean(this.map)">
        <slot :google="google" :map="map"></slot>
      </template>
    </div>
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
  props: {
    apiKey: { type: String, required: true },
    mapConfig: {
      type: Object,
      default: () => ({ zoom: 8 }),
    },
  },
  data() {
    return {
      google: null,
      map: null,
    };
  },
  methods: {
    initializeMap() {
      this.google = window.google;
      this.map = new this.google.maps.Map(this.$refs.googleMap, this.mapConfig);
      const vthis = this;
      const searchBox = new this.google.maps.places.SearchBox(this.$refs.autocompleteMap);
      //console.log("🚀 ~ file: GoogleMapLoader.vue ~ line 46 ~ initializeMap ~ searchBox", searchBox)

      let markers = null;
      searchBox.addListener("places_changed", () => {
        const places = searchBox.getPlaces();
        //console.log("🚀 ~ file: GoogleMapLoader.vue ~ line 50 ~ searchBox.addListener ~ places", places)

        if (places.length == 0) {
          return;
        }
        
        //console.log("🚀 ~ file: GoogleMapLoader.vue ~ line 53 ~ searchBox.addListener ~ places", places)

        // Clear out the old markers.
        // markers.forEach((marker) => {
        //   marker.setMap(null);
        // });
        
        this.$emit('removeOldMarkers', true);
        if(markers){
          markers.setMap(null);
          this.google.maps.event.clearInstanceListeners(markers);
          markers = null;
        }
        
    

        // For each place, get the icon, name and location.
        const bounds = new this.google.maps.LatLngBounds();

        const place = places[0];
        //console.log("🚀 ~ searchBox.addListener ~ place:", place.geometry.location.lat())
        //places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          console.log("Returned place contains no geometry");
          return;
        }
        let ubicacionBusqueda = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
        this.$emit('drag', ubicacionBusqueda);

        
        // Create a marker for each place.
        markers = new this.google.maps.Marker({
            map: vthis.map,
            icon: POINT_MARKER_ICON_CONFIG,
            title: place.name,
            position: place.geometry.location,
            draggable: true,
          })
        
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        markers.addListener('dragend', (event) => {
          let ubicacion = { lat: event.latLng.lat(), lng: event.latLng.lng() };
          this.$emit('drag', ubicacion);
        });
        //});


        vthis.map.fitBounds(bounds);
      });


    },
  },
  mounted() {
    const loader = new Loader({ apiKey: this.apiKey, version: 'weekly', libraries: ['places'] });
    loader
      .load()
      .then(this.initializeMap)
      .catch((error) => {
        console.log('Error al cargar Google Maps', error);
      });
  },
};

const POINT_MARKER_ICON_CONFIG = {
  path: 'M 0, 0 m -5, 0 a 5,5 0 1,0 10,0 a 5,5 0 1,0 -10,0',
  strokeOpacity: 1,
  strokeWeight: 5,
  strokeColor: '#FFFFFF',
  fillColor: '#4A62D4',
  fillOpacity: 1,
  scale: 3,
};
</script>

<style lang="sass" scoped>
.google-map
  min-height: 100%
  width: 100%
</style>

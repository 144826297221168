<script>
export default {
  name: 'GoogleMapMarker',
  props: {
    google: { type: Object, required: true },
    map: { type: Object, required: true },
    options: { type: Object, required: true },
    removeOldMarkers: {}
  },
  data() {
    return {
      marker: null,
    };
  },
  watch: {
    'removeOldMarkers': function(){
      this.eliminarMarcadores();
    }
  },
  mounted() {
    const { Marker } = this.google.maps;

    this.marker = new Marker({
      ...this.options,
      map: this.map,
    });

    this.marker.addListener('dragend', (event) => {
      const ubicacion = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      this.$emit('drag', ubicacion);
    });
  },
  methods: {
    eliminarMarcadores(){
      if(this.marker){
        this.marker.setMap(null);
        this.google.maps.event.clearInstanceListeners(this.marker);
        this.marker = null;
      }
    }
  },
  beforeDestroy() {
    console.log('beforeDestroy this.marker', this.marker);
    if(!this.marker){
      return
    }
    this.marker.setMap(null);
    this.google.maps.event.clearInstanceListeners(this.marker);
    this.marker = null;
  },
  render() {
    return null;
  },
};
</script>

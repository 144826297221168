<template>
  <FullLayout>
    <div class="h-full overflow-hidden relative viewport w-full">
      <google-map-loader :apiKey="apiKey" :mapConfig="mapOptions" @drag="updateLocation" @removeOldMarkers="remove++">
        <template slot-scope="{ google, map }">
          <camera-map-marker
            :camera="camera.current"
            :google="google"
            :map="map"
            @drag="updateLocation"
            :removeOldMarkers="remove"
          />
        </template>
      </google-map-loader>

      <div class="flex items-center absolute top-8 left-8 overflow-auto">
        <div>
          <project-image :project="project.current" />
        </div>
        <div class="ml-7">
          
        </div>
      </div>
    </div>

    <div slot="actionbar">
      <div class="grid grid-cols-4 gap-4 py-2">
        <div class="text-left inline-block ml-6">
          <button
            type="button"
            class="rounded-full bg-primary focus:outline-none focus:ring h-10 w-10 ml-2"
            @click="$router.back()"
          >
            <i class="tmlps-i-arrow-left text-3xl h-8 w-8 text-white"></i>
          </button>
          <div class="text-sm text-left">Regresar</div>
        </div>
        <div class="col-span-2 flex flex-col">
          <h3 class="text-center text-base font-bold">Ubicación de la cámara</h3>
          <span class="text-sm text-center">Puedes ingresar una dirección o mover el pin</span>
        </div>
        <div class="flex justify-end mr-6">
          <BaseButton class="h-10 w-50 ml-3" @click="handleConfirm">
            Confirmar ubicación
          </BaseButton>
        </div>
      </div>
    </div>
  </FullLayout>
</template>

<script>
import config from '@/config';
import { mapState } from 'vuex';
import FullLayout from '@/layouts/FullLayout';
import BaseButton from '@/components/BaseButton';

import ProjectImage from '@/components/ProjectImage';
import GoogleMapLoader from '@/components/GoogleMapLoader';
import CameraMapMarker from '@/components/CameraMapMarker';
export default {
  name: 'camera-project-location',
  components: {
    FullLayout,
    BaseButton,
    ProjectImage,
    GoogleMapLoader,
    CameraMapMarker,
  },
  data() {
    return {
      remove: 7,
      location: '',
      apiKey: config.GOOGLE_MAPS_API_KEY,
      mapOptions: {
        center: {
          lat: 0,
          lng: 0,
        },
        zoom: 17,
        mapTypeId: 'satellite',
        disableDefaultUI: true,
        zoomControl: true,
      },
      loading: false,
    };
  },
  computed: {
    ...mapState(['project', 'camera']),
    project_id() {
      return this.$route.params.project_id;
    },
    camera_id() {
      return this.$route.params.camera_id;
    },
  },
  methods: {
    async handleConfirm() {
      const formData = new FormData();
      formData.append('name', this.camera.current.name);
      formData.append('timezone', this.camera.current.timezone);
      formData.append('capture_start_time', this.camera.current.capture_start_time);
      formData.append('capture_end_time', this.camera.current.capture_end_time);
      formData.append(
        'capture_interval_seconds',
        Number(this.camera.current.capture_interval * 60),
      );
      formData.append('preferred_selection_time', this.camera.current.preferred_selection_time);

      formData.append('type', this.camera.current.type);

      if (this.camera.current.type == 'third-party') {
        this.camera.current.sync_from_ftp = false;
        formData.append('embed_script', this.camera.current.embed_script);
      }

      formData.append('sync_from_ftp', Number(this.camera.current.sync_from_ftp));

      formData.append('generate_videos_automatic', Number(this.camera.current.generate_videos_automatic));
      if(this.camera.current.generate_videos_automatic){
        formData.append('quality', this.camera.current.quality);
      }

      if (this.camera.current.sync_from_ftp) {
        formData.append(
          'delete_synced_photos_from_source',
          Number(this.camera.current.delete_synced_photos_from_source),
        );
        formData.append(
          'delete_empty_photos_from_source',
          Number(this.camera.current.delete_empty_photos_from_source),
        );
        formData.append('ftp_address', this.camera.current.ftp_address);
        formData.append('ftp_port', this.camera.current.ftp_port);
        formData.append('ftp_username', this.camera.current.ftp_username);
        formData.append('ftp_password', this.camera.current.ftp_password);
        formData.append('ftp_path', this.camera.current.ftp_path);
      }

      formData.append('latitude', this.mapOptions.center.lat);
      formData.append('longitude', this.mapOptions.center.lng);

      this.loading = true;

      try {
        await this.$store.dispatch('camera/updateCamera', {
          cameraId: this.camera_id,
          data: formData,
        });

        this.$store.dispatch(
          'notification/addSuccess',
          'La ubicación de la cámara ha sido confirmada correctamente',
        );
        this.$router.push({ name: 'project-home', params: { project_id: this.project_id } });
      } catch (error) {
        if (error.response && error.response.data.errors) {
          this.$store.dispatch('notification/addError', error.response.data.errors);
          this.errors.capture(error.response.data.errors);
        }
      } finally {
        this.loading = false;
      }
    },
    updateLocation(marker) {
      //console.log("🚀 ~ file: Location.vue ~ line 161 ~ updateLocation ~ marker", marker)
      this.mapOptions.center.lat = marker.lat;
      this.mapOptions.center.lng = marker.lng;
    },
  },
  async created() {
    this.mapOptions.center.lat = this.camera.current.latitude;
    this.mapOptions.center.lng = this.camera.current.longitude;
  },
};
</script>

<style lang="sass" scoped>
.viewport
  background: #242424
</style>

<template>
  <div class="flex flex-col h-screen max-h-full max-w-full overflow-hidden relative w-full">
    <section class="flex-grow main-content overflow-y-auto relative">
      <slot>
        <p>Aqui va el contenido</p>
      </slot>
    </section>

    <section class="actionbar relative p-2 w-full">
      <slot name="actionbar">
        <p>Aqui va el actionbar</p>
      </slot>
    </section>
  </div>
</template>

<script>
export default {
  name: 'FullLayout',
};
</script>

<style lang="scss" scoped>
.actionbar {
  background-color: white;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
}
</style>
